.project {

    tr.child {
        background-color: #f0f0f0;

        td {
            position: relative;
        }

        td:not(:first-child)::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            height: 90%;
            border-left-width: 1px;
            border-left-style: solid;
        }

        &.red {
            td::before {
                border-left-color: red;
            }
        }

        &.blue {
            td::before {
                border-left-color: rgb(45, 45, 255);
            }
        }

        &.green {
            td::before {
                border-left-color: green;
            }
        }

        &.done {
            td::before {
                border-left-color: black;
            }
        }
    }

    .map {
        display: flex;
        flex-grow: 1;
        height: calc(93vh - 160px);
        position: relative;

        .selector {
            padding: 10px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
    }
}

.propose-list {
    padding: 20px;

    table {
        td {
            padding: 5px 10px;

            button {
                padding: 0px;
            }
        }
    }
}

.project-form {
    @media (min-width: 769px) {
        height: 93vh;
    }

    display: flex;
    flex-direction: column;
    background-color: white;

    &.attachments {
        height: inherit;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        .item {
            position: relative;
            margin: 5px;
            height: 200px;
            width: 200px;
            border: 1px solid lightgray;

            .action {
                position: absolute;
                right: 0px;
                padding: 5px;

                &.start {
                    left: 0px;
                }
            }

            img {
                object-fit: contain;
                height: 75%;
                width: 100%;
            }

            &.files {
                height: 50px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .map {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .selector {
            width: 300px;
            padding: 10px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
    }

    .footer {
        border-top: lightgrey;
        border-top-width: 1px;
        border-top-style: solid;
        padding: 10px;
        display: flex;
        justify-content: space-evenly;
    }
}

.fielding-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: white;

    @media (min-width: 970px) {
        // @media (min-width: 769px) {
        overflow: hidden;
        height: 93vh;
    }

    @media (max-width: 970px) {
        overflow-y: scroll;
    }

    .map-container {
        flex-direction: column;

        .map {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            position: relative;

            .selector {
                width: 300px;
                padding: 10px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
            }
        }
    }

    .fielding-detail {
        @media (min-width: 970px) {
            width: 25%;
            height: 92vh;
            // height: calc(93vh - 75px);
            overflow: auto;
            // margin-top: $menu_height;
        }

        @media (max-width: 970px) {
            width: 100%;
            border-top: 1px solid gray;
            margin-top: 40px;
            padding-top: 40px;
        }

        padding: 10px;

        .attachments {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            max-height: 300px;
            overflow: auto;

            .item {
                cursor: pointer;
                position: relative;
                margin: 5px;
                height: 100px;
                width: 100px;
                border: 1px solid lightgray;

                .action {
                    position: absolute;
                    right: 0px;

                    &.start {
                        left: 0px;
                    }
                }

                img {
                    object-fit: contain;
                    height: 100%;
                    width: 100%;
                }

                &.files {
                    padding-left: 8px;
                    height: 50px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    padding-left: 40px;
                }
            }
        }
    }
}

.fielding-type {
    display: flex;
    margin: 8px 0px;

    .MuiInputBase-root {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    button {
        @media (min-width: 769px) {
            white-space: nowrap;
        }

        @media (max-width: 768px) {
            max-height: 35.644px;
        }

        min-width: auto;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.fielding-logs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    label {
        font-weight: 500;
        margin-bottom: 6px;
    }

    button {
        // min-width: 300px;
        padding: 8px 14px;
        border-radius: 20px;
        text-align: left;
        background-color: white;

        ul {
            background-color: #1072f1;
        }
    }

    ul {
        background-color: #1072f1;
    }
}

.fielding-assign {
    min-height: 93vh;
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }

    background-color: white;

    .container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .map {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            position: relative;
            min-height: 50vh;

            .selector {
                width: 300px;
                padding: 10px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
            }
        }
    }

    .detail {
        @media (min-width: 769px) {
            width: 25%;
        }

        padding: 10px;
        min-width: 320px;

        &.scrollable {
            height: calc(93vh);
            overflow: auto;
        }
    }
}

.detail-map {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 93vh;

    .map {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;

        .selector {
            width: 300px;
            padding: 10px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
    }
}

.text-nowrap {
    text-wrap: nowrap;
}