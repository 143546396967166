table.storage {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
        padding: 8px;
        text-align: left;
        white-space: nowrap;
    }

    thead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
        border-bottom: 1px solid #ddd;
    }
}

.user-storage-select {
    margin-left: 4px;
    width: 100%;
    .MuiSelect-select {
        padding: 6px;   
    }
    .default-user {
        // position: absolute;
        // font-size: 10px;
        // right: 10px;
        // color: gray;
        // font-weight: normal;
        display: none;
    }
}
#menu-user-storage-select {
    .MuiPaper-root {
        top: 230px;
        left: 220px;
    }
}
.custom-storage {
    .MuiList-padding {
        padding: 0;
    }
}
.filename-wrap {
    text-wrap: nowrap;
    position: absolute;
    bottom: 0;
    left: 2px;
    margin: 0;
    font-size: 11px;
}
