$primary: #063058;
$primary-grad: #ffffff;
$secondary: #EFECFE;
$bg: #E5E5E5;
$color_menu_active: #80B8DD;
$menu_height: 85px;

@import '../../../node_modules/@sweetalert2/theme-material-ui/material-ui.scss';

@import '../../containers/containers.scss';

@import '../../pages/auth/auth.scss';
@import '../../pages/project/project.scss';
@import '../../pages/credit/credit.scss';
@import '../../pages/fielding/fielding.scss';

@import '../../shared/customModal/customModal.scss';

@import '../../pages/storage/storage.scss';
@import '../../pages/myaccount/myaccount.scss';

:root {
    .swal2-container {
        z-index: 3000;
    }

    .swal2-popup .swal2-styled:focus {
        box-shadow: none !important;
        background-color: #7066e0;
    }

    .swal2-actions {
        button {
            border-radius: 20px;
        }
    }

    .MuiBackdrop-root {
        &.loading {
            z-index: 3000;
        }
    }

    font-size: small;

    button {
        text-transform: none;
    }

    .esri-zoom .esri-widget--button {
        display: none;
    }

    .esri-track .esri-widget--button {
        width: 150px;
    }

    input:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
    }

    .Mui-selected.view-switch {
        background-color: #03c04a !important;
        color: white !important;
    }
}

.menu-header {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.scrolling {
        height: $menu_height;
        min-width: 1100px;
    }

    .back {
        height: $menu_height;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        span {
            padding: 5px;
            white-space: nowrap;
        }
    }

    >button {
        height: $menu_height;
        border-radius: 0px;
    }

    >div {
        margin: 0px 4px 4px 4px;
        display: flex;
        align-items: center;

        >div {
            margin-left: 10px;
        }
    }
}

.page-header {
    min-height: $menu_height;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 0px 8px;

    .nav-row {
        margin-top: 8px;
        color: gray;
    }
}

table.custom-table {
    margin-top: 20px;
    background-color: white;
    width: 100%;
    border-radius: 20px;
    border-collapse: separate;
    border-spacing: 0;

    .space {
        height: 20px;
        background-color: $bg;
    }

    tr.hover:hover {
        background-color: #80b8dd41;
    }

    th {
        padding: 10px 20px;
        text-align: left;
    }

    td {
        padding: 10px 20px;
    }

    .foot {
        display: flex;
        justify-content: right;
        align-items: center;
        padding: 10px;
    }
}

.image_preview {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {

        position: relative;

        .close {
            border-radius: 20px;
            background-color: white;
            position: absolute;
            right: -10px;
            top: -10px;
        }

        img {
            border: 5px solid white;
            height: 90vh;
            width: 90vw;
            object-fit: contain;
            background-color: white;
            border-radius: 10px;
        }

        .download {
            color: white !important;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
        }

        .left {
            position: absolute;
            left: -16px;
            top: 50%;
            transform: translate(0, -50%);
            background-color: white;
            border-radius: 50%;
        }

        .right {
            position: absolute;
            right: -16px;
            top: 50%;
            transform: translate(0, -50%);
            background-color: white;
            border-radius: 50%;
        }
    }
}

.image-detail {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {

        position: relative;

        .close {
            border-radius: 20px;
            background-color: white;
            position: absolute;
            right: -10px;
            top: -25px;
        }

        .container {
            width: 90vw;
            background-color: white;
            border-radius: 10px;

            img {
                height: 90vh;
                width: 45vw;
                object-fit: contain;
                background-color: white;
                border-radius: 10px;
            }

            .table,
            table,
            th,
            td {
                border: 1px solid black;
                border-collapse: collapse;

                td {
                    padding: 8px;
                }
            }
        }

        .download {
            color: white !important;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
        }

        .left {
            position: absolute;
            left: -16px;
            top: 50%;
            transform: translate(0, -50%);
            background-color: white;
            border-radius: 50%;
        }

        .right {
            position: absolute;
            right: -16px;
            top: 50%;
            transform: translate(0, -50%);
            background-color: white;
            border-radius: 50%;
        }
    }

}

.map-container {
    display: flex;
    flex-grow: 1;
    min-height: 50px;

    .google-container {
        display: flex;
        flex-grow: 1;
        position: relative;
    }
}

.arcgis-container {
    display: flex;
    flex-grow: 1;
    position: relative;

    .map-pallete {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        background-color: rgba($color: #ffffff, $alpha: 0.75);

        &.transparent {
            background-color: transparent;
        }

        &.top-right {
            flex-direction: column;
            border-bottom-left-radius: 23px;
            border-bottom-right-radius: 23px;
            right: 10px;
            width: 46px; // 36*BUTTONS + 20
        }

        &.top-center {
            flex-direction: column;
            top: 10px;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 5px 12px;
        }

        &.end {
            flex-direction: row;
            border-bottom-left-radius: 23px;
            border-top-left-radius: 23px;
            right: 0px;
            top: 220px;
            padding: 5px 12px;
        }

        &.pegman {
            flex-direction: row;
            border-bottom-left-radius: 23px;
            border-top-left-radius: 23px;
            right: 0px;
            bottom: 85px;
            padding: 5px 12px;
        }

        &.done-shape {
            flex-direction: row;
            border-bottom-left-radius: 23px;
            border-top-left-radius: 23px;
            right: 0px;
            bottom: 138px;
            padding: 5px 12px;

            button {
                margin: 5px;
            }
        }

        &.map-switch {
            flex-direction: row;
            border-bottom-left-radius: 23px;
            border-top-left-radius: 23px;
            right: 0px;
            bottom: 25px;
            padding: 5px 12px;

            button {
                margin: 5px;
            }
        }
    }
}

img.bg {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

.drop-zone {
    max-width: none !important;
    min-width: none !important;
    height: 100px !important;
    display: flex !important;
    flex-grow: 1 !important;
    justify-content: center !important;
    align-items: center !important;

    div {
        flex-grow: 0;
    }
}

.esri-search {
    width: 150px;
}

.MuiCardHeader-action {
    margin-left: 1rem;
    display: flex;
    flex: auto !important;
    justify-content: flex-end;
    align-items: flex-start;
}