.fielding {

    .list {
        height: calc(93vh - 50px);
        overflow: auto;
    }

    .selector {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;

        .MuiTabs-flexContainer {
            background-color: #fff;
            width: fit-content;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        .toggle-button {
            display: flex;
            margin-left: 10px;
            align-items: center;
            height: 40px;
        }
    }

    .container {
        height: 85vh;
        overflow: auto;
    }

    .map {
        display: flex;
        flex-grow: 1;
        height: calc(93vh - 50px);
        position: relative;

        .selector {
            padding: 10px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
    }
}

.go-fielding-detail {
    height: 93vh;
    display: flex;
    flex-direction: column;

    .map {
        display: flex;
        flex-grow: 1;
        height: 93vh;
        position: relative;

        .selector {
            padding: 10px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
    }
}

.blinking-circle, .blinking-circle::before {
    content: " ";
    margin: 15px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 auto;
    transition: all 0.1s;
}

.blinking-circle::before {
    animation: mymove 1s infinite;
    position: absolute;
    background-color: rgb(156, 156, 156);
}

@keyframes mymove {
    50%   {
        transform: scale(2);
        opacity: 0
    }
    100%   {
        transform: scale(2);
        opacity: 0
    }
}

@-webkit-keyframes mymove {
    50%   {
        transform: scale(2);
        opacity: 0
    }
    100%   {
        transform: scale(2);
        opacity: 0
    }
}