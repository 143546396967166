header {
    height: 7vh;
    background: linear-gradient(90deg, $primary-grad 5%, $primary 100%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 0px;

    .brand {
        color: black;

        @media (min-width: 769px) {
            margin-left: 15px;
        }

        display: flex;
        align-items: center;

        button {
            margin: 0px 20px 0px 6px;

            svg {
                width: 34px;
                height: 34px;
                border-radius: 10px;
                padding: 5px;
            }
        }

        img {
            height: 5vh;
            max-height: 48px;
        }

        span {
            margin-left: 10px;

            @media (min-width: 769px) {
                font-size: x-large;
            }

            @media (max-width: 768px) {
                font-size: small;
            }
        }
    }

    .profile {
        div {
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: end;
        }
    }
}

.main {
    display: flex;
    height: 93vh;

    .sidetab {
        background: $primary-grad;
        display: flex;
        flex-direction: row;

        .MuiButtonBase-root {
            height: $menu_height;
        }

        .MuiList-padding {
            padding: 0px;
        }

        .MuiListItemButton-root {
            @media (max-width: 768px) {
                width: 60px;
            }

            display: flex;
            justify-content: center;
            font-weight: bold !important;

            .MuiListItemIcon-root {
                display: flex;
                justify-content: center;

                svg {
                    color: rgb(82, 82, 82);
                    width: 34px;
                    height: 34px;
                    border-radius: 10px;
                    padding: 5px;
                }
            }
        }

        .MuiListItemButton-root.active {
            background-color: $color_menu_active;

            svg {
                color: $secondary;
            }
        }

        .sidetab-expand {
            background-color: $primary;
            color: white;

            .MuiListItemButton-root {
                justify-content: flex-start;
                width: 150px;
            }
        }
    }

    .content {
        width: 100vw;
        background-color: $bg;
        overflow-y: auto;
        overflow-x: hidden;
    }
}