.signin {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
        background-color: rgba($color_menu_active, 0.7);
        padding: 50px 100px 75px 100px;
        border-radius: 100px;
    }
}

.pdfcontainer {
    overflow-x: hidden;
}

.pdfpage {
    padding-bottom: 20px;
    background-color: rgb(56, 56, 56) !important;
}