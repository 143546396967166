.working-area {
    height: 80vh;
    width: 75vw;
    display: flex;
    flex-direction: column;

    .map {
        display: flex;
        flex-grow: 1;
        position: relative;

        .selector {
            padding: 10px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
    }
}