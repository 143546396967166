.custom-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    .custom-modal-content {
        .MuiCardHeader-root {
            background-color: $primary;
            color: white;
        }

        @media (min-width: 769px) {
            max-width: 80vw;
        }

        @media (max-width: 768px) {
            width: 90vw;
        }

        &.sm {

            @media (min-width: 769px) {
                width: 30vw;
                min-width: 500px;
            }
        }

        &.md {

            @media (min-width: 769px) {
                width: 50vw;
                min-width: 500px;
            }
        }

        &.lg {

            @media (min-width: 769px) {
                width: 70vw;
                min-width: 500px;
            }
        }

        &.xl {

            @media (min-width: 769px) {
                width: 95vw;
                height: 95vh;
                min-width: 500px;
            }
        }

        .MuiCardHeader-root {
            span {
                font-size: 20px;
                font-weight: bold;
            }
        }

        .MuiCardContent-root {
            max-height: 80vh;
            overflow: auto;

            &.fullHeight {
                max-height: 95vh;
            }
        }

    }
}